<template>
  <vx-card>
    <h1>تعديل حزمة</h1>
    <div class="mb-5">&#8203;</div>
    <div class="vx-row">
      <div class="vx-col my-1 w-full mb-6"></div>
    </div>
    <div>
      <div class="vx-row">
        <div class="vx-col my-3 sm:w-1/3 w-full mb-2">
          <vs-input
            class="w-full"
            label-placeholder="إسم الحزمة"
            data-vv-as="إسم الحزمة"
            name="name"
            v-validate="'required'"
            v-model="productPackage.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">
            {{ errors.first("name") }}
          </span>
        </div>
        <div class="vx-col my-3 sm:w-1/6 w-full mb-2">
          <vs-input
            type="number"
            class="w-full"
            label-placeholder="السعر الكلي"
            data-vv-as="السعر الكلي"
            name="total"
            v-validate="'required|numeric'"
            v-model="productPackage.total"
          />
          <span class="text-danger text-sm" v-show="errors.has('total')">
            {{ errors.first("total") }}
          </span>
        </div>
      </div>

      <!-- // category -->
      <div class="vx-row mt-8">
        <div class="vx-col w-full mb-6">
          <h4 class="mb-4" for="">إدراج المنتجات</h4>
          <vs-table :data="tableData">
            <template slot="thead">
              <vs-th>SKU رقم</vs-th>
              <vs-th>إسم المنتج</vs-th>
              <vs-th>الكمية المحددة</vs-th>
              <vs-th>العمليات</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].sku">
                  {{ data[indextr].sku }}
                </vs-td>

                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name }}
                </vs-td>

                <vs-td :data="data[indextr].pivot.quantity" v-if="data[indextr].pivot">
                  {{ data[indextr].pivot.quantity }}
                </vs-td>
                 <vs-td v-else>
                  {{ data[indextr].quantity }}
                </vs-td>

                <vs-td>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="deleteProduct(indextr)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="vx-row flex items-center">
            <div class="vx-col my-3 sm:w-1/3 w-full mb-2">
              <label class="vs-input--label"> اختر رقم sku</label>
              <v-select
                name="sku"
                class="w-full"
                data-vv-as="sku"
                data-vv-scope="order-items"
                label="sku"
                :options="products"
                v-model="product"
                :tabindex="4"
                :filterable="true"
              ></v-select>
            </div>
            <div class="vx-col my-3 sm:w-1/6 w-full mb-2">
              <vs-input
                type="number"
                class="w-full"
                label-placeholder="الكمية"
                v-model="sell_quantity"
                ref="input_quantity"
              />
            </div>
            <div class="vx-col my-3 sm:w-1/4 w-full mb-2 flex self-end">
              <vs-button
                class="mx-1"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                @click="addProduct"
              ></vs-button>
              <vs-button
                class="mx-1"
                color="warning"
                type="filled"
                icon-pack="feather"
                icon="icon-rotate-ccw"
                @click="resetInputs"
              ></vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mt-8 flex justify-end">
        <div class="vx-col sm:w-1/2 w-full mb-6 flex justify-end">
          <vs-button
            class="mr-5 text-lg py-4"
            @click="$router.go(-1)"
            color="warning"
            type="border"
            >إلغاء</vs-button
          >
          <vs-button
            class="mr-5 text-lg px-16 py-4"
            @click="submit"
            color="success"
            type="filled"
            >حفظ التعديلات</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moduleProduct from "@/store/products/moduleProduct.js";
import vSelect from 'vue-select'

export default {
  props: ["tr"],
  components: {vSelect},
  data() {
    return {
      // products info in the taple
      tableData: [],
      
      // for add product inputs
      product: {},
      sell_quantity:0,      // package info
      productPackage: {
        id: null,
        name: "",
        total: null,
        products: [],
      },
    };
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
  },
  methods: {
    //add product to the package
    addProduct() {
          let new_product = { 
          id: this.product.id,
          name:this.product.name,
          sku:this.product.sku,
          product_quantity:this.product.quantity,
          quantity: this.sell_quantity>0 ? this.sell_quantity : 1
        }
        let duplicated = this.tableData.find(item => item.sku == new_product.sku);
        if(duplicated){
          this.$vs.notify({
            title:'حدث خطأ',
            text:'المنتج موجود مسبقا',
            color:'danger'
          });
          return false;
        }

        this.tableData.push(new_product)
    },
    // delete added product
    deleteProduct(index) {
       this.tableData.splice(index,1)
    },
    //clear add product inputs
    resetInputs() {
      this.$refs.input_sku.value = null;
      this.$refs.input_quantity.value = null;
    },
    // save changes
    async submit() {
      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });
      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      //fix duplication when error happend
      this.productPackage.products = [];

      // check if admin add at least one product to the package
      if (this.tableData.length <= 1) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى إضافة منتجين علي الأقل",
          color: "danger",
        });
        return false;
      }
      // loop through slected products in the taple and brign {id:.. , quantity:..}
      this.tableData.forEach((item) => {
        let product = {
          id: item.id,
          quantity: item.pivot ? item.pivot.quantity :item.quantity,
        };
        // push the product to the products array
        this.productPackage.products.push(product);
      });
      // make data in json format
      let formdata = new FormData();
      formdata.set("id", this.productPackage.id);
      formdata.set("name", this.productPackage.name);
      formdata.set("total", Number(this.productPackage.total));
      formdata.set("products", JSON.stringify(this.productPackage.products));
      this.$store
        .dispatch("packages/updatePackage", formdata)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم اضافة المنتج بنجاح",
            color: "success",
          });
          this.$router.push({ name: "packages" });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    
    handleSelected(tr) {
      this.$vs.notify({
        title: `Selected ${tr.username}`,
        text: `Email: ${tr.email}`,
      });
    },
  },
  created() {
    if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
   this.$store.dispatch("getAllProducts");
  },
  mounted() {
    // Current package data
    this.productPackage.id = this.tr.id;
    this.productPackage.name = this.tr.name;
    this.productPackage.total = this.tr.total;
    this.tableData = this.tr.products;
  },
};
</script>

<style scoped>
.product-image {
  cursor: pointer;
  transition: all 0.3s;
}

.product-image:hover {
  transform: scale(0.9);
}

.image-container {
  border-radius: 10px;
  margin: 10px;
  width: 200;
  height: 200px;
  border: 2px solid transparent;
}

.image-container:hover {
  border: 2px solid rgba(255, 0, 0, 0.39);
}
</style>
